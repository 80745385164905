import React, { useEffect, useState, useRef } from "react";
import { LogoLayout } from "../../Components/LogoLayout";
import { SelectForm } from "../../Components/shared/Select";
import { InputGroup } from "../../Components/shared/Input";
import SlideOver from "../../Components/shared/SlideOver";
import Button from "../../Components/shared/Button";
import { useForm } from "react-hook-form";
import { apiPrivate } from "../../api/api";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import * as zod from "zod";
import { v4 as uuid } from 'uuid';
import { useSearchParams, useNavigate } from "react-router-dom";
import { validarCPF } from "../../Utils/cpf-validator";
import { validateExtension } from "../../Utils/extension-validator";


import {
  ArrowUpTrayIcon,
  ArrowPathIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { forEach } from "lodash";

const baseUrl = process.env.REACT_APP_API_URL;

const defaultAssinanteValues = {
  nome: "Pessoa",
  email: "pessoa@example.com",
  telefone: "(11) 1000-2000",
  cpf: "321.312.321-88",
  papel: "3",
};

const defaultContrato = {
  conta: "4EE24277-576F-4034-6566-08DB13F4A838",
  codigoContrato: uuid(),
  nomeEvento: ""
};

const UploadContract = () => {

  const navigate = useNavigate();
  //setCodigoContrato(guid);


  const api = apiPrivate();
  const [isSaved, setIsSaved] = useState(false);
  const [assinantes, setAssinantes] = useState([]);
  const [loading, setLoading] = useState(false);
  const classLoading = loading ? 'opacity-20' : '';
  // const [papeis, setPapeis] = useState([]);
  // const [documentType, setDocumentType] = useState([]);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");

  const [arquivos, setArquivos] = useState([]);
  const [papeis, setPapeis] = useState([
    { value: "1", label: "Papel 1" },
    { value: "2", label: "Papel 2" },
    { value: "3", label: "Papel 3" },
  ]); //TODO: Remover dados mocks
  const [documentType, setDocumentType] = useState([
    { value: 0, label: "Todos" },
    { value: 1, label: "Documentos assinados" },
    { value: 2, label: "Documentos não assinados" },
  ]); //TODO: Remover dados mocks

  const [contas, setContas] = useState([
    { value: '4EE24277-576F-4034-6566-08DB13F4A838', label: "CodHalum" }
  ]); //TODO: Remover dados mocks

  const [simnao, setsimnao] = useState([
    { value: 1, label: "Sim" },
    { value: 0, label: "Não" },
  ]);


  useEffect(() => {
    const fetchData = async () => {
      const responsePapeis = await api.get("/getPapeis"); // TODO: mudar endpoint
      const responseDocumentType = await api.get("/getDocumentType"); // TODO: mudar endpoint
      const responseClients = await api.get("/getClients"); // TODO: mudar endpoint
      setContas(responseClients);
      setPapeis(responsePapeis.data);
      setDocumentType(responseDocumentType.data);
    };
    // fetchData();  // TODO: descomentar quando tiver o endpoint
  }, []);

  const assinanteFormSchema = zod.object({
    nome: zod.string().min(1, "Campo obrigatório").max(80),
    email: zod
      .string()
      .email("Insira um email válido")
      .min(1, "Campo obrigatório"),
    telefone: zod.string().min(1, "Campo obrigatório"),
    cpf: zod.string().min(1, "Campo obrigatório"),
    papel: zod.string().min(1, "Campo obrigatório").max(50),
    canFinish: zod.string(),
    canCancel: zod.string()

  });

  const assinanteForm = useForm({
    // defaultValues: defaultAssinanteValues,
    mode: "onChange",
    resolver: zodResolver(assinanteFormSchema),
  });

  const contractFormSchema = zod.object({
    conta: zod.string().min(1, "Campo obrigatório"),
    codigoContrato: zod.string().min(1, "Campo obrigatório"),
    nomeEvento: zod.string().min(1, "Campo obrigatório"),
    documentoParticipante: zod.string().optional(),
    nomeParticipante: zod.string().optional()
    // tipoDocumento: zod.string().min(1, "Campo obrigatório"),

  });

  const contractForm = useForm({
    mode: "onChange",
    defaultValues: defaultContrato,
    resolver: zodResolver(contractFormSchema),
  });

  const onSubmitAssinante = async (data) => {
    console.log(data);

    const numbersOnly = data.cpf.match(/\d+/g).join('');
    if (numbersOnly.length != 11) {
      toast("CPF inválido!", {
        type: 'error',
        autoClose: 2000,
        theme: 'colored'
      });

      return;
    }

    if (!validarCPF(numbersOnly)) {
      toast("CPF inválido!", {
        type: 'error',
        autoClose: 2000,
        theme: 'colored'
      });

      return;
    }

    const isDuplicated = assinantes.filter(function (signer) {
      return signer.cpf == data.cpf;
    });

    if (isDuplicated.length > 0) {

      toast("CPF já cadastrado como assinante!", {
        type: 'error',
        autoClose: 2000,
        theme: 'colored'
      });

      return;
    }


    setAssinantes([...assinantes, data]);
    assinanteForm.reset();
    handleSlideOverToggle(false); // caso precise fechar a modal ao incluir assinante
  };

  const onSubmitContract = async (data) => {
    setLoading(true);
    console.log(data);
    try {
      let payload = {
        ...data,
        assinantes,
      };

      if (fileList.length == 0) {
        toast("Obrigatório inserir um arquivo!", {
          type: 'error',
          autoClose: 2000,
          theme: 'colored'
        });
        setLoading(false);
        return;
      }

      console.log("payload", payload);
      console.log("file_list", fileList);
      const formData = new FormData();
      formData.append("ClientId", payload.conta);

      for (const file of fileList) {
        console.log(file.file);
        formData.append("Files", file.file);
      }

      console.log("formData", formData);

      // enviar a lista de arquivos para o endpoint para subir no blob
      // const response = await apiPrivate().post(`${baseUrl}batch/resend-emails/${id}/?signerId=${signerId}`, { validateStatus: () => true });
      const response = await apiPrivate().post(`${baseUrl}files-storage/upload-documents/`, formData, { validateStatus: () => true });

      var dataResponde = response.data;

      var docs = [];

      if (response.status === 200) {

        var assinantesRequest = [];

        for (const signer of assinantes) {
          var signerRequest = {
            email: signer.email,
            phoneNumber: signer.telefone,
            name: signer.nome,
            vatNumber: signer.cpf.replace('.', '').replace('.', '').replace('-', ''),
            signType: signer.papel,
            canCancelBatchGroup: signer.canCancel == "1",
            canFinishBatchGroup: signer.canFinish == "1"
          }

          assinantesRequest.push(signerRequest);
        }

        for (const doc of dataResponde.documents) {
          var document = {
            eDocumentType: 6,
            documentName: doc.name,
            path: doc.url,
            signers: assinantesRequest
          }
          docs.push(document);
        }

        var participant = {
          name: payload.nomeParticipante,
          vatNumber: payload.documentoParticipante
        }

        var request = {
          clientId: payload.conta,
          controlNumber: payload.codigoContrato,
          batchGroupName: payload.nomeEvento,
          batchGroupType: 8, // type contract
          documents: docs,
          participant: participant
        }

        const response = await apiPrivate().post(`${baseUrl}allinone/allinone-by-user/`, request, { validateStatus: () => true });

        if (response.status === 200) {
          setLoading(false);
          toast("Evento criado!", {
            type: 'success',
            autoClose: 2000,
            theme: 'colored'
          });

          setIsSaved(true);
          navigate("/signs");
        }
        else if (response.status === 400 || response.status === 401 || response.status === 403 || response.status === 500) {
          toast("Falha ao criar evento, contate o administrador do sistema!", {
            type: 'error',
            autoClose: 2000,
            theme: 'colored'
          });
          setLoading(false);

        }


      }
      else if (response.status === 401 || response.status === 403) {
        toast("Falha ao cadastrar o contrato, sua autenticação expirou, faça o login novamente!", {
          type: 'error',
          autoClose: 2000,
          theme: 'colored'
        });
        setLoading(false);
      }
      else if (response.status === 400 || response.status === 500) {
        toast("Falha ao cadastrar o contrato, contate o administrador do sistema!", {
          type: 'error',
          autoClose: 2000,
          theme: 'colored'
        });
        setLoading(false);

      }

      console.log(data);
    } catch (error) {
      console.log("error", error);
      toast("Erro ao cadastrar contrato", {
        type: "error",
        autoClose: 5000,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const [fileList, setFileList] = useState([]);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  async function fetchFile() {
    try {
      const formData = new FormData();
      formData.append("arquivo", fileList);
      const response = await api.post(`/upload-contract-file`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        toast("Arquivo enviado com sucesso!", {
          type: "success",
          autoClose: 5000,
          theme: "colored",
        });
      } else {
        throw new Error("Erro ao enviar arquivo");
      }
    } catch (error) {
      toast("Erro ao enviar arquivo, contate o administrador do sistema", {
        type: "error",
        autoClose: 5000,
        theme: "colored",
      });
    }
  }

  let [filesMap, setFilesMap] = useState({});
  const handleFileChange = async (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files)
      .map((file) => {

        if (!validateExtension(file)) {
          toast(`Arquivo ${file.name} com extensão inválida`, {
            type: "error",
            autoClose: 5000,
            theme: "colored",
          });
          return null;
        }

        console.log(filesMap);
        if (!filesMap[file.name]) {
          setFilesMap({ ...filesMap, [file.name]: file.name });
          return {
            name: file.name,
            file: file,
          };
        } else {
          toast(`Arquivo ${file.name} já adicionado`, {
            type: "error",
            autoClose: 5000,
            theme: "colored",
          });
          return null;
        }
      })
      .filter((file) => file !== null);

    if (newFiles.length) {
      setFileList((prevFiles) => [...prevFiles, ...newFiles]);
      fileInputRef.current.value = "";
    }
  };

  const handleDeleteFile = (fileName) => {
    if (!fileName) return;
    setFilesMap({ ...filesMap, [fileName]: null });
    setFileList((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const handleDeleteAssinante = (cpf) => {
    if (!cpf) return;
    setAssinantes((prevAssinantes) =>
      prevAssinantes.filter((assinante) => assinante.cpf !== cpf)
    );
  };

  const handleSubmit = (event) => {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  const [open, setOpen] = useState(false);
  const handleSlideOverToggle = (value) => {
    setOpen(value);
  };

  return (
    <LogoLayout>

      {loading && (
        <div className="flex flex-col items-center justify-center mt-5">
          <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      )}

      {!loading &&


        <form
          onSubmit={contractForm.handleSubmit(onSubmitContract)}
          className="text-left"
        >
          <div>
            <h1 className="font-medium text-xl leading-tight tracking-tight text-gray-800 md:text-2xl mb-4">
              Cadastro de contratos
            </h1>
            <div className="flex gap-4">
              <SelectForm
                label="Cliente"
                options={contas}
                className="sm:w-full"
                form={contractForm}
                id="conta"
              />
              <InputGroup
                label="Código do contrato"
                type="text"
                className="opacity-50 cursor-not-allowed bg-gray-200 rounded-md px-2 py-2 w-full"
                form={contractForm}
                id="codigoContrato"
                disabled="disabled"
              />
            </div>

            <InputGroup
              label="Título do evento"
              placeholder="Nome do evento"
              type="text"
              className="md:w-full"
              form={contractForm}
              id="nomeEvento"
            />
            <div className="flex gap-4">

              <InputGroup
                label="Documento do participante"
                placeholder="CPF ou CNPJ do Participante"
                type="text"
                className="md:w-full"
                form={contractForm}
                id="documentoParticipante"
              />

              <InputGroup
                label="Nome do Participante"
                placeholder="Nome do Participante"
                type="text"
                className="md:w-full"
                form={contractForm}
                id="nomeParticipante"
              />
            </div>

            <div className="w-1/2 my-10">
              <div className="flex justify-between mb-4">
                <h1 className="font-medium text-xl leading-tight tracking-tight text-gray-800 md:text-2xl">
                  Arquivos

                </h1>


                <input
                  ref={fileInputRef}
                  type="file"
                  name="arquivo"
                  id="arquivo"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />

                <div className="flex gap-4">

                  <Button
                    type="button"
                    onClick={handleFileButtonClick}
                    className="inline-flex items-center bg-cyan-700 hover:bg-cyan-900"
                  >
                    <span>Selecionar Arquivo</span>
                    <PlusIcon className="h-4 w-4 ml-2" />
                  </Button>

                </div>

              </div>
              <small>Extensões: .pdf e .pkcs7</small>

              <table
                className={
                  "w-full border-collapse bg-white text-left text-sm text-gray-500 z-10  relative rounded-lg border border-gray-200 shadow-md overflow-x-auto"
                }
              >
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-4 font-medium text-gray-900 text-start"
                    >
                      Arquivo
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-4 font-medium text-gray-900 text-start"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fileList.map((file, index) => (
                    <tr key={`${file.name}-${index}`}>
                      <td className="px-3 py-4  text-gray-900 text-sm font-medium">
                        {file.name}
                      </td>
                      <td className="px-3 py-4  text-red-600 text-sm font-medium w-20 text-center">
                        <button onClick={() => handleDeleteFile(file.name)}>
                          <XMarkIcon className="h-6 w-6" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-12">
            <div className="flex justify-between mb-4">
              <div>
                <h1 className="font-medium text-xl leading-tight tracking-tight text-gray-800 md:text-2xl">
                  Assinantes
                </h1>
              </div>
              <Button type="button" onClick={() => handleSlideOverToggle(true)}>
                Novo Assinante
              </Button>
            </div>
            <table
              className={
                "w-full border-collapse bg-white text-left text-sm text-gray-500 z-10  relative rounded-lg border border-gray-200 shadow-md overflow-x-auto"
              }
            >
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-4 font-medium text-gray-900 text-start"
                  >
                    Nome
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 font-medium text-gray-900 text-start"
                  >
                    Papel
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 font-medium text-gray-900 text-start"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-4 font-medium text-gray-900 text-start"
                  >
                    Telefone
                  </th>
                  <th scope="col" className="px-3 py-4 font-medium text-gray-900">
                    CPF
                  </th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody
                className={"divide-y divide-gray-100 border-t border-gray-100"}
              >
                {assinantes.map((pessoa) => (
                  <tr key={pessoa.cpf} className="hover:bg-gray-50">
                    <td className="px-3 py-4  text-gray-900 text-sm font-medium">
                      {pessoa.nome}
                    </td>
                    <td className="px-3 py-4  text-gray-900 text-sm font-medium">
                      {pessoa.papel}
                    </td>
                    <td className="px-3 py-4  text-gray-900 text-sm font-medium">
                      {pessoa.email}
                    </td>
                    <td className="px-3 py-4  text-gray-900 text-sm font-medium">
                      {pessoa.telefone}
                    </td>
                    <td className="px-3 py-4  text-gray-900 text-sm font-medium">
                      {pessoa.cpf}
                    </td>
                    <td className="px-3 py-4  text-red-600 text-sm font-medium w-20 text-center">
                      <button onClick={() => handleDeleteAssinante(pessoa.cpf)}>
                        <XMarkIcon className="h-6 w-6" />
                      </button>
                    </td>
                  </tr>
                ))}

                {assinantes.length <= 0 ? (
                  <tr className="text-center">
                    <td
                      colSpan="7"
                      className="px-2 py-4 font-medium text-gray-700"
                    >
                      Nenhum item na lista
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end mt-12">
            {!isSaved &&
              <Button
                type="submit"
                className={"bg-green-600 hover:bg-green-800 disabled:bg-gray-500"}
              // disabled={!contractForm.formState.isValid || assinantes.length <= 0}
              >
                Cadastrar contrato
              </Button>
            }

          </div>
        </form>
      }
      <SlideOver
        dialogTitle="Incluir assinante"
        isOpen={open}
        handleToggle={handleSlideOverToggle}
      >
        <form onSubmit={assinanteForm.handleSubmit(onSubmitAssinante)}>
          <InputGroup type="text" form={assinanteForm} id="nome" label="Nome" />
          <InputGroup
            type="text"
            form={assinanteForm}
            id="email"
            label="Email"
          />
          <InputGroup
            type="text"
            form={assinanteForm}
            id="telefone"
            mask="(99) 99999999?"
            preserveMask="true"
            formatChars={{ 9: "[0-9]", t: "[0-9-]", "?": "[0-9 ]" }}
            maskChar={null}
            label="Telefone"
          />
          <InputGroup
            label="CPF"
            mask="999.999.999-99"
            form={assinanteForm}
            preserveMask="true"
            id="cpf"
          />

          <InputGroup
            type="text"
            label="Papel"
            form={assinanteForm}
            id="papel"
          />
          {/* <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <input type="radio" id="tipoFinalizar" name="tipoEvento" />
              <label htmlFor="tipoFinalizar">Finalizar</label>
            </div>
            <div className="flex items-center gap-2">
              <input type="radio" id="tipoFechar" name="tipoEvento" />
              <label htmlFor="tipoFechar">Fechar</label>
            </div>
          </div> */}

          <SelectForm
            label="Permissão de Finalizar"
            options={simnao}
            className="sm:w-full"
            form={assinanteForm}
            id="canFinish"
          />

          <SelectForm
            label="Permissão de Cancelar"
            options={simnao}
            className="sm:w-full"
            form={assinanteForm}
            id="canCancel"
          />
          <div className="flex justify-between">
            {/* <div className="flex items-center gap-2">
              <InputGroup
                type="radio"
                label="Permissão de Finalizar"
                form={assinanteForm}
                id="canFinish"
              />
              <InputGroup
                type="radio"
                label="Permissão de Cancelar"
                form={assinanteForm}
                id="canCancel"
              />


            </div> */}

            {/* <div className="flex items-center gap-2">
              <input type="checkbox" id="tipoFinalizar" name="canCancel" />
              <label htmlFor="tipoFinalizar">Permissão de Finalizar</label>
            </div>
            <div className="flex items-center gap-2">
              <input type="checkbox" id="tipoFechar" name="canFinish" />
              <label htmlFor="tipoFechar">Permissão de Fechar</label>
            </div> */}
          </div>

          <Button type="submit" className="w-full mt-3">
            Salvar
          </Button>
          {/* <Button  type="submit" value="Submit" className="w-full mt-3">
            Incluir assinante
          </Button> */}
        </form>
      </SlideOver>

    </LogoLayout>
  );
};

export default UploadContract;
