import { InfoMessage } from "../../Components/InfoMessage";
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import ErrorMessage from "../../Components/ErrorMessage";
import { useCallback } from "react";
import FullWidthModal from "../../Components/FullWidthModal";
import DocumentView from "../../Components/DocumentView";
import { DocumentListView } from "../../Components/DocumentListView";
import { LogoLayout } from "../../Components/LogoLayout";


const baseUrl = process.env.REACT_APP_API_URL;

const DocumentListViewPage = () => {
    const navigate = useNavigate();

    const [signerBatchData, setSignerBatchData] = useState({
        // documents: [],
        signerId: '',
        signerBatchId: '',
        batchGroupId: '',
        clientName: '',
        batchName: '',
        signerName: '',
        allDocumentsSigned: false,
        isFinished: false,
        isFullSignature: false,
    });
    const [signerBatchDataLoaded, setSignerBatchDataLoaded] = useState(false);

    // const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();

    const [geolocation, setGeolocation] = useState(undefined)
    const [geolocationError, setGeolocationError] = useState(false);
    const [showError, setShowError] = useState(false);
    const [msgError, setMsgError] = useState(false);
    const [showDocumentViewModal, setShowDocumentViewModal] = useState({ title: '', show: false, content: null });

    const hash = searchParams.get('hash');


    useEffect(() => {

        if (!hash) {
            navigate('/');
            return;
        }



        async function getSignerBatchInfo() {
            getGeolocation();

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = await fetch(baseUrl + 'files-storage/signer-batch/' + hash, requestOptions);

            if (response.status === 200) {
                const data = await response.json();
                setSignerBatchData(data);
            }
            else if (response.status === 400) {
                navigate('/token-validation?hash=' + hash);
            }

            setLoading(false);
            setSignerBatchDataLoaded(true);

        }

        if (!signerBatchDataLoaded) {
            setLoading(true);
            getSignerBatchInfo();
        }

    }, [hash, navigate, signerBatchDataLoaded]);



    const getGeolocation = useCallback(() => {
        if (navigator.geolocation && !geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);

                setGeolocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
                setGeolocationError(false);
                // const Test = () => (<span>teste</span>);

            }, function (error) {
                console.log("erro ao solicitar geolocalização: ", error);
                setGeolocationError(true);
            });
        }
    }, [geolocation]);

    const checkGeolocationPermission = useCallback(() => {
        getGeolocation();
    })


    useEffect(() => {
        if (signerBatchDataLoaded && !geolocation && !signerBatchData.isSigned) {
            getGeolocation();
        }
    }, [signerBatchData, geolocation, getGeolocation, signerBatchDataLoaded]);


    const SignDocuments = () => {
        console.log("assinando ...");
        if (!geolocation || geolocationError)
            throw new Error('não pode assinar sem geolocation.');

        console.log("token", localStorage.getItem("token"));

        var jsonBodySign = {
            hash: hash,
            latitude: String(geolocation.latitude),
            longitude: String(geolocation.longitude),
            token: localStorage.getItem("token")
        };

        console.log("json", jsonBodySign);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(jsonBodySign)
        };


        // chama função de assinar
        setLoading(true);
        fetch(baseUrl + 'certification/sign-documents', requestOptions)
            .then(
                (result) => {
                    if (result.status === 200) {
                        setSignerBatchDataLoaded(false);
                    } else if (result.status === 400) {
                        result.json().then(responseObject => {
                            if (responseObject.detail != null) {
                                setMsgError(responseObject.detail);
                            }
                        });
                        setShowError(true);
                    }
                    setLoading(false);
                },
                (error, statuserro) => {
                    setLoading(false);
                    console.log(error);
                    console.log(statuserro);
                }
            )
    }

    const openModalViewDocument = (documentName, documentId, signed) => {
        setShowDocumentViewModal({ title: 'Documento: ' + documentName, show: true, content: () => (<DocumentView documentId={documentId} signed={signed} />) });
    }

    const closeModal = () => {
        setShowDocumentViewModal({ title: '', show: false, content: null });
    };

    return (
        <LogoLayout>


            {loading && (
                <div className="flex flex-col items-center justify-center mt-5">
                    <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}

            {!loading && (
                <div className="text-center">
                    <h2 className="text-lg uppercase">{signerBatchData.clientName}</h2>
                    <h2 className="text-lg">{signerBatchData.batchName}</h2>
                    <h3 className="text-lg">{signerBatchData?.participant?.name} {signerBatchData?.participant?.vatnumber}</h3>
                    <span className="text-base">Para: </span><span className="text-base font-bold capitalize">{signerBatchData.signerName}</span>

                    <DocumentListView
                        signerId={signerBatchData.signerId}
                        batchGroupId={signerBatchData.batchGroupId}
                        signerBatchId={signerBatchData.signerBatchId}
                        isFinished={signerBatchData.isFullSignature}
                        onClickDocument={openModalViewDocument} />

                    {(!geolocationError && !signerBatchData.isFinished && !signerBatchData.allDocumentsSigned && !signerBatchData.isFullSignature) &&
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-5 disabled:opacity-25" onClick={SignDocuments} >
                            Assinar
                            {loading && (
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            )}
                        </button>
                    }

                    {geolocationError && (
                        <>
                            <ErrorMessage title={'Não foi possível obter localização:'}
                                msg={'para assinar documentos na Hermes deve permitir a captura de geolocalização no navegador.'} />

                        </>
                    )}
                    {showError && <ErrorMessage title={'Erro ao assinar documentos. '} msg={msgError ?? 'Erro ao assinar'} />}


                    {(signerBatchData.isFinished) &&

                        <div className="flex flex-col items-center justify-center mt-5">
                            <InfoMessage title="Operação finalizada." message="Fluxo de assinatura de documentos finalizado." />
                        </div>
                    }

                    {(!signerBatchData.isFinished && signerBatchData.isFullSignature && !signerBatchData.allDocumentsSigned) &&
                        <div className="flex flex-col items-center justify-center mt-5">
                            <InfoMessage title="Operação finalizada." message="Gerando documentos, aguarde a finalização do fluxo. Você receberá uma cópia dos documentos assinados." />
                        </div>
                    }


                    {(!signerBatchData.isFinished && signerBatchData.allDocumentsSigned) &&
                        <div className="flex flex-col items-center justify-center mt-5">
                            <InfoMessage title="Todos os documentos foram assinados." message="Aguarde a finalização do fluxo. Você receberá uma cópia dos documentos." />
                        </div>
                    }
                </div>
            )}

            <FullWidthModal showModal={showDocumentViewModal.show} title={showDocumentViewModal.title} onClose={closeModal} content={showDocumentViewModal.content} />
        </LogoLayout>

    );
}


export default DocumentListViewPage;

